import { AxiosResponse } from 'axios';
import JsonApiFormatter from '@/services/api/formatters/json-api';
import ContentTypes from '@/constants/api-content-types';

/* eslint-disable-next-line import/no-default-export */
export default (response: AxiosResponse) => {
  const { data, headers } = response;
  let formattedData = null;
  console.log(response, 'response');
  if (headers['content-type']?.includes(ContentTypes.jsonapi)) {
    formattedData = {
      data: data ? JsonApiFormatter.deserialize(data) : {},
      meta: data && data.meta ? data.meta : {},
    };
  }

  return {
    ...response,
    data: formattedData || data,
  };
};
